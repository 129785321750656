import styled from 'styled-components';

export const Container = styled.div`
  background-color: #FFF;
  box-shadow: 0px 5px 14px rgba(0,0,0, 0.1);
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  >div{

    .itens {
      display: flex;
      flex-direction: column;

      span {
        font-size: 10px;
        color:#959595;
      }
    } 

    h1{
      font-size: 12px;
      color: #444444;
      font-weight: bold;
    }
    h2{
      font-size: 12px;
      color:#444444;
      font-weight: 300;
    }
    .detalhes{
      margin-top: 5px;
      margin-bottom: 10px;

      h1{
        font-weight: 300;
        padding: 10px 0;
      }
    }
    .status{
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      span{
        font-weight: bold;
      }
      span.entregue{
        color: #273294;
      }
      span.processando{
        color: #008305;
      }
      span.aguardando{
        color: #3C86E3;
      }
      span.cancelado{
        color: #FF0000;
      }
    }
  }
`