import styled from 'styled-components'
import {
  UTILS
} from '../../../../services/links.json'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  border-radius: 5px;
  background-color: #FFF;
  box-shadow: 0px 5px 14px rgba(0,0,0,0.1);
  padding: 0 10px;
  margin-bottom: 10px;
  position: relative;
`

export const Time = styled.span`
  position: absolute;
  top: 5px;
  width: 100%;
  text-align: center;
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  
  h2{
    font-size: 12px;
    font-weight: bold;
    color: #444444;
  }
`

export const Topo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1{
    font-size: 12px;
    font-weight: bold;
    color: #444444;
  }

  div {
    padding-top: 9px;
    font-size: 10px;
    color: #959595;
  }
  
`

export const RightSide = styled.div`
  display: flex;
  align-self: center;
  width: 80px;
  height: 80px;
  /* background-image: url(${({ bg }) =>  bg});
  background-position: 100% 100%;
  background-size: cover; */
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

`

export const CarrinhoButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
`
