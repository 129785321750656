import USER from './actions/user'
import LANGUAGE from './actions/language'
import CART from './actions/cart'
import CONFIG from './actions/config'
import * as LOCALSTORAGE from '../../localStorage'

const initial_state = {
  lang: LOCALSTORAGE.lang.get() || 'pt',
  cart: {
    room: [],
    amenities: []
  }
}

// REDUCER
// FUNCAO QUE SEMPRE É CHAMADA QUANDO QUERO MUDAR O ESTADO
export default (state = initial_state, action) => {
  switch (action.type) {
    case USER.type:
      return USER.fall(state, action)
    case LANGUAGE.type:
      return LANGUAGE.fall(state, action)
    case CART.type:
      return CART.fall(state, action)
    case CONFIG.type:
      return CONFIG.fall(state, action)
    default:
      return state
  }
}
