import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import API, { endpointers } from '../../../../services/api'
import * as LOCALSTORAGE from '../../../../services/localStorage'
import { submit_state } from '../../../../utils'

import * as SU from '../../../../utils/styles'
import * as S from './style'

import seta from '../../../../assets/images/FaleConosco/Sucesso/seta-baixo.png'
import sucess from '../../../../assets/images/FaleConosco/Sucesso/sucesso.svg'
import Header from '../../Header'

const _data = {
	categoria_id: '',
	titulo: '',
	descricao: '',
	setor: '',
	_schema: 'manutencao',
}

function FaleConosco({ redux_lang, redux_user }) {
	const [submit, setSubmit] = useState({ ...submit_state })
	const [isToggle, setToggle] = useState(false)
	const [categories, setCategories] = useState([])

	const { query = {} } = useLocation()
	const { push } = useHistory()

	const getSetor = useCallback(() => {
		switch (query._schema) {
			case 'tecnologia':
				return 'TI'
			default:
				return ''
		}
	}, [query])

	const [data, setData] = useState({
		..._data,
		setor: getSetor(),
		_schema: query._schema || 'manutencao',
		cliente: {
			id: redux_user?.IdHospede,
			sobrenome: redux_user?.Sobrenome,
			apartamento: redux_user?.CodUH,
		},
	})

	const getCategories = useCallback(async () => {
		const { data: dataFromApi } = await API(
			endpointers.get.category({ _schema: query._schema || 'manutencao' })
		)

		if (dataFromApi.ok) {
			setCategories(dataFromApi.data)
		}
	}, [])

	useEffect(() => {
		getCategories()
	}, [])

	const { addToast } = useToasts()

	const _handleSubmit = useCallback(
		async (e) => {
			e.preventDefault()

			if (submit.req) return

			if (!data.categoria_id.length) {
				addToast(redux_lang === 'pt' ? 'Selecione o tipo' : 'Select type', {
					autoDismiss: true,
				})

				return
			}

			if (redux_user?.adm) {
				if (!data.setor.length) {
					addToast('Informe seu setor', {
						autoDismiss: true,
					})

					return
				}
			}

			setSubmit({ ...submit_state, req: true })

			let status = true

			try {
				const { data: dataFromApi } = await API.post(
					endpointers.post.order,
					{ ...data },
					{ headers: { Authorization: LOCALSTORAGE.token.get() } }
				)
				if (!dataFromApi.ok) {
					throw new Error(dataFromApi.message)
				}
				setData({
					..._data,
					setor: getSetor(),
					_schema: query._schema || 'manutencao',
					cliente: {
						id: redux_user?.IdHospede,
						sobrenome: redux_user?.Sobrenome,
						apartamento: redux_user?.CodUH,
					},
				})
			} catch (e) {
				status = false
				console.error(e)
				addToast(
					redux_lang === 'pt' ? 'Ocorreu um erro' : 'An error has occurred',
					{
						appearance: 'error',
						autoDismiss: true,
					}
				)
			} finally {
				setSubmit({
					req: false,
					done: true,
					status,
				})
			}
		},
		[data, submit, redux_user, redux_lang]
	)

	useEffect(() => {
		if (submit.done && submit.status) {
			setToggle(true)
		}
	}, [submit])

	if (!redux_user) {
		push(
			'/logar',
			query
				? {}
				: {
						redirect: 'sevicos-de-manutencao',
				  }
		)
		return null
	}

	return (
		<>
			<Header />
			<S.Container onSubmit={_handleSubmit}>
				<h1>
					{redux_lang === 'pt' ? 'Manutenção' : 'Maintenance'} {getSetor()}
				</h1>
				<select
					name='assunto'
					id='assunto'
					value={data.categoria_id}
					onChange={({ target: { value: categoria_id } }) =>
						setData((oldState) => ({ ...oldState, categoria_id }))
					}
				>
					<option value=''>
						{redux_lang === 'pt' ? 'Selecione o tipo' : 'Select type'}...
					</option>
					{categories
						.sort((a, b) =>
							a.texto[redux_lang].localeCompare(b.texto[redux_lang])
						)
						.map(({ _id, texto }) => (
							<option value={_id} key={_id}>
								{texto[redux_lang]}
							</option>
						))}
				</select>
				{redux_user?.adm && !getSetor() ? (
					<input
						required
						type='text'
						value={data.setor}
						onChange={({ target: { value: setor } }) =>
							setData((oldState) => ({ ...oldState, setor }))
						}
						placeholder='Setor'
					/>
				) : null}

				<input
					type='text'
					name=''
					id=''
					value={data.titulo}
					onChange={({ target: { value: titulo } }) =>
						setData((oldState) => ({ ...oldState, titulo }))
					}
					placeholder={
						redux_lang === 'pt'
							? 'Digite um título (opcional)'
							: 'Enter a title (optional)'
					}
				/>
				<textarea
					required
					value={data.descricao}
					onChange={({ target: { value: descricao } }) =>
						setData((oldState) => ({ ...oldState, descricao }))
					}
					placeholder={
						redux_lang === 'pt' ? 'Descreva o problema' : 'Describe the problem'
					}
				></textarea>
				<button type='submit' disabled={submit.req}>
					{submit.req
						? `${redux_lang === 'pt' ? 'Enviando' : 'Sending'} ...`
						: redux_lang === 'pt'
						? 'Enviar'
						: 'Send'}
				</button>
			</S.Container>

			<SU.Modal className={isToggle ? 'expandido' : 'escondido'}>
				<S.Conteudo>
					<S.Cabecalho>
						<button
							onClick={(_) => {
								setToggle(false)
								setSubmit({ ...submit_state })
							}}
						>
							<img src={seta} alt='' />
						</button>
						<h1>{redux_lang === 'pt' ? 'Manutenção' : 'Maintenance'}</h1>
					</S.Cabecalho>
					<SU.ModalSuccessResponse>
						<img src={sucess} alt='' />
						<h1>
							{redux_lang === 'pt'
								? 'Pedido efetuado com sucesso!'
								: 'Order placed successfully!'}
							!
						</h1>
						<button
							onClick={() => {
								push('/meuspedidos')
							}}
						>
							{redux_lang === 'pt' ? 'Meus pedidos' : 'My requests'}
						</button>
					</SU.ModalSuccessResponse>
				</S.Conteudo>
			</SU.Modal>
		</>
	)
}

const mapStateToProps = (state) => ({
	redux_lang: state.lang,
	redux_user: state.user,
})

export default connect(mapStateToProps)(FaleConosco)
