import axios from 'axios'

import * as UTILS from '../utils'

import { API } from './links.json'

export default axios.create({
	baseURL: API.PRODUCTION,
	timeout: 10000,
})

export const endpointers = {
	get: {
		room(query = {}) {
			return `auth/user/room?${UTILS.getQuery(query)}`
		},
		amenitie(query = {}) {
			return `auth/user/amenitie?${UTILS.getQuery(query)}`
		},
		catalog(query = {}) {
			return `auth/user/catalog?${UTILS.getQuery(query)}`
		},
		concierge(query = {}) {
			return `auth/user/concierge?${UTILS.getQuery(query)}`
		},
		order(id) {
			return `user/order/${id}`
		},
		category(query = {}) {
			return `user/category?${UTILS.getQuery(query)}`
		},
		latest_services_prices: 'auth/services-prices',
		config: 'auth/config',
	},
	post: {
		room: 'auth/user/order/room',
		amenitie: 'auth/user/order/amenitie',
		maintenance: 'auth/user/order/maintenance',
		login: 'user/login',
		order: 'auth/user/order',
	},
	reconnect: 'auth/user/app/reconnect',
}
