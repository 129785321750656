import React from 'react';
import * as S from './style'

import {
  UTILS 
} from '../../../../services/links.json'

function Item({titulo, descricao, preco, imagem, time, _handleExpand}) {
  return (
    <button onClick={ _handleExpand }>
      <S.Container>
        <S.LeftSide>
          <S.Topo>
            <h1>{titulo}{ time ? ` - ${ time }` : '' }</h1>
            <div dangerouslySetInnerHTML={{ __html: descricao || '' }}></div>
          </S.Topo>
          { 
            preco ? (
              <h2>{preco}</h2>
            ) : null 
          }
        </S.LeftSide>
        <S.RightSide 
          // bg={ `${  imagem }` }
          bg={ imagem }
        >
          <img 
            // src={ `${  imagem }` } 
            src={ imagem }
            alt=""
          />
        </S.RightSide>
      </S.Container>
    </button>
  )
}

export default Item;