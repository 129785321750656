import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
`

export const Barra = styled.div`
  width: 100%;
  height: 50px;
  background-color: #273294;
  display: flex;
  justify-content: center;
  a{
    margin: 0 10px;
    position: relative;
    top: -10px;
  }
`