const type = 'CONFIG'

export default {
  type,
  call(data) {
    return {
      type,
      data
    }
  },
  fall(state, action) {
    const new_state = { ...state }

    new_state.config = action.data

    return new_state
  }
}
