import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import { Provider } from 'react-redux'
import { ToastProvider } from 'react-toast-notifications';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import store from './services/redux'

import seta from './assets/select.svg'

const GlobalStyle = createGlobalStyle`
  body {
    background: #F7F7F7;  
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  select {
    background: url(${ seta }) no-repeat 98%/12px auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`



ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <ToastProvider>
        <Routes/>
      </ToastProvider>
    </Provider>
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
