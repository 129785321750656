// ESSA PROPRIEDADE É PARA IDENTIFICAR O QUE EU QUERO MUDAR NO ESTADO
const type = 'CART'

export default {
  type,
  // call() É A FUNCAO PARA RETORNAR UM OBJETO QUE QUE VAI SER O action DO fall()
  // CHAMADO  PELOS COMPONENTES
  call(list = [], field) {
    return {
      type,
      data: {
        list, field
      }
    }
  },
  // fall() RECEBE O ESTADO ATUAL E O OBJETO RETORNADO PELA FUNCAO ACIMA call()
  // CHAMADO PELO REDUCER E DEVE RETORNAR O ESTADO ATUALIZADO
  fall(state, action) {
    const new_state = { ...state }

    if (action.data.field) {
      new_state.cart[action.data.field] = action.data.list
    }

    return new_state
  }
}
