import styled from 'styled-components'

export const Modal = styled.div`
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: #FFF;
  bottom: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  transition: bottom ease-in-out 0.5s;

  &:after {
    content: '';
    background: linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.2), transparent);
    position: absolute;
    top: -50vh;
    left: 0;
    right: 0;
    height: 50vh;
  }

  &.expandido{
    transition: bottom ease-in-out 0.5s;
    bottom: 0;

  }
  &.escondido{
    transition: bottom ease-in-out 0.5s;
    bottom: -150vh;
  }
`

export const ModalSuccessResponse = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  >button{
    max-width: 500px;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
    margin-top: 35px;
  }

  img{
    max-width: 80px;
    margin-top: 50px;
  } 
  h1{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    max-width: 185px;
    color: #444444;
    margin-top: 20px;
  }
  span{
    margin-top: 20px;
    font-size: 12px;
    color: #444444;
  }
`