import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import * as S from './style'

import CONFIG from '../../services/redux/reducer/actions/config'
import USER from '../../services/redux/reducer/actions/user'

import * as LOCALSTORAGE from '../../services/localStorage'
import SOCKET from '../../services/socket'

import fechar from '../../assets/images/Login/fechar.svg'

function Formulario({
	redux_setUser,
	redux_setConfig,
	redux_user,
	redux_lang,
}) {
	const { push } = useHistory()

	const _handleExit = useCallback(() => {
		SOCKET.disconnect()
		redux_setUser(null)
		redux_setConfig(null)
		LOCALSTORAGE.token.remove()
		LOCALSTORAGE.admUser.remove()
		push('logar')
	}, [])

	if (!redux_user) {
		push('/logar', {
			redirect: 'perfil',
		})
		return null
	}

	return (
		<S.Container>
			<Link className='fechar' to='/'>
				<img src={fechar} alt='' />
			</Link>
			<div className='logado'>
				{redux_user?.adm ? (
					<h1>{redux_user.username || ''}</h1>
				) : (
					<h1>{redux_user?.Nome + ' ' + redux_user?.Sobrenome || ''}</h1>
				)}
				{redux_user?.adm ? (
					<h2>Email: {redux_user?.email || ''}</h2>
				) : (
					<h2>
						{redux_user
							? redux_lang === 'pt'
								? 'Apartamento'
								: 'Apartment'
							: ''}{' '}
						{redux_user?.CodUH || ''}
					</h2>
				)}
				<div className='acoes'>
					<Link className='button' to='/meuspedidos'>
						<button className='button'>
							{redux_lang === 'pt' ? 'Meus Pedidos' : 'My requests'}
						</button>
					</Link>
					<button className='sair' onClick={_handleExit}>
						{redux_lang === 'pt' ? 'Sair' : 'Exit'}
					</button>
				</div>
			</div>
		</S.Container>
	)
}

//
const mapDispatchToProps = (dispatch) => ({
	redux_setUser(data) {
		dispatch(USER.call(data))
	},
	redux_setConfig(data) {
		dispatch(CONFIG.call(data))
	},
})

const mapStateToProps = (state) => ({
	redux_user: state.user,
	redux_lang: state.lang,
})

export default connect(mapStateToProps, mapDispatchToProps)(Formulario)
