import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import USER from '../../services/redux/reducer/actions/user'

import API, { endpointers } from '../../services/api'
import * as LOCALSTORAGE from '../../services/localStorage'
import SOCKET from '../../services/socket'

import * as S from './style'

import Formulario from '../../components/Login/Formulario'

//imagens
import fechar from '../../assets/images/Login/fechar.svg'

function Login({ redux_setUser }) {
	const [reconecting, setReconecting] = useState(
		LOCALSTORAGE.token.get()?.length
	)
	const { query = {}, state = {} } = useLocation()
	const { push } = useHistory()

	const { addToast } = useToasts()

	const reAuth = useCallback(async () => {
		try {
			const { data: dataFromApi } = await API(endpointers.reconnect, {
				headers: { Authorization: LOCALSTORAGE.token.get() },
			})

			if (!dataFromApi.ok) {
				throw new Error(dataFromApi.message)
			}

			redux_setUser({
				...dataFromApi.data,
				token: dataFromApi.token,
			})

			LOCALSTORAGE.token.set(dataFromApi.token)

			SOCKET.connect()
			addToast(
				`Bem-vindo(a) ${
					dataFromApi.data.Nome + ' ' + dataFromApi.data.Sobrenome
				}`,
				{ autoDismiss: true }
			)
			push('/')
		} catch (e) {
			console.error(e)
			LOCALSTORAGE.token.remove()
			addToast('Não foi possível reconectar', { autoDismiss: true })
		} finally {
			setReconecting(false)
		}
	}, [])

	useEffect(() => {
		const userAdm = LOCALSTORAGE.admUser.get()

		if (userAdm) {
			//       {
			//     "_id": "6127901109a42be9f87315eb",
			//     "username": "capiba",
			//     "username_key": "capiba",
			//     "email": "cpbx@capiba.com.br",
			//     "level": 1,
			//     "created_at": 1629982737298,
			//     "token": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7ImFkbSI6dHJ1ZSwiX2lkIjoiNjEyNzkwMTEwOWE0MmJlOWY4NzMxNWViIiwibGV2ZWwiOjEsInVzZXJuYW1lIjoiY2FwaWJhIn0sImV4cCI6MTY4ODUxODc2NywiaWF0IjoxNjg3OTEzOTY3fQ.YQaePQ2eD9znMqYdhMMlpA4OZtoTnPdEATunTsZw5Qc"
			// }

			const user = JSON.parse(userAdm)

			LOCALSTORAGE.token.set(user.token)

			redux_setUser({
				IdHospede: user._id,
				Sobrenome: user.username,
				CodUH: 0,
				Nome: '',
				...user,
				token: user.token,
				adm: true,
			})

			SOCKET.connect()
			addToast(`Bem-vindo(a) ${user.username}`, { autoDismiss: true })
			push('/')
			setReconecting(false)
		} else {
			if (LOCALSTORAGE.token.get()?.length) {
				LOCALSTORAGE.admUser.remove()
				addToast('Reconectando ...', { autoDismiss: true, appearance: 'info' })
				reAuth()
			}
		}
	}, [])

	return (
		<S.Container>
			<Link className='fechar' to='/'>
				<button>
					<img src={fechar} alt='' />
				</button>
			</Link>
			{reconecting ? (
				<h1>Reconectando ...</h1>
			) : (
				<Formulario redirect={query.redirect || state.redirect} />
			)}
		</S.Container>
	)
}

const mapStateToProps = (_) => ({})

const mapDispatchToProps = (dispatch) => ({
	redux_setUser(data) {
		dispatch(USER.call(data))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
