import React from 'react';
import { 
  BrowserRouter as Router, 
  Switch, Route 
} from 'react-router-dom';

//components
import Home from './views/Home';
import Login from './views/Login'
import ServicosDeQuartos from './components/Home/Servicos/Quartos'
import ServicosDeAmenidades from './components/Home/Servicos/Amenidades'
import ServicosDeManutencao from './components/Home/Servicos/Manutencao'
import ServicosDeCatalogos from './components/Home/Servicos/Catalogos'
import ServicosDeConcierge from './components/Home/Servicos/Concierge'
import MeusPedidos from './views/MeusPedidos'
import DetalhePedido from './views/DetalhePedido'
import Contato from './views/FaleConosco'
import Perfil from './views/Perfil'

const Routes = () => {
  const baseUrl = document.getElementsByTagName('base')[0]?.getAttribute('href')

  return (
    <Router basename={ baseUrl ? baseUrl : '' }>
      <Switch>
        <Route path="/" component={Home} exact/>
        <Route path="/logar" component={Login} />
        {/* Home */}
        <Route path="/servicos-do-restaurante" component={ServicosDeQuartos } />
        <Route path="/servicos-de-governanca" component={ServicosDeAmenidades} />
        <Route path="/informacoes-do-hotel" component={ServicosDeCatalogos} />
        <Route path="/informacoes-da-cidade" component={ServicosDeConcierge} />
        <Route path="/sevicos-de-manutencao" component={ServicosDeManutencao} />

        <Route path="/meuspedidos" component={MeusPedidos} exact />
        <Route path="/meuspedidos/detalhe" component={DetalhePedido} />
        <Route path="/entre-em-contato" component={Contato} />
        <Route path="/perfil" component={Perfil} />
        <Route component={() => <div>Page 404</div>} />
      </Switch>
    </Router>
  )
}

export default Routes;