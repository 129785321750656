import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import * as S from './styles'

import LANGUAGE from '../../../services/redux/reducer/actions/language'

//imagens
import logo from '../../../assets/images/Home/header/logo.png'
import web from '../../../assets/images/Home/header/web.svg'
import home from '../../../assets/images/Home/header/home.svg'
import about from '../../../assets/images/Home/header/about.svg'
import brasil from '../../../assets/images/Home/header/brasil.svg'
import states from '../../../assets/images/Home/header/states.svg'
import fechar from '../../../assets/images/Home/header/fechar.svg'

function Header({ redux_lang, redux_user, redux_setLang }) {
  const [isToggle, setToggle] = useState(false)

  return (
    <>
     <S.Content className={isToggle ? 'expandid' :  'escondid'}>
      <button onClick={() => setToggle(false)}>
        <img src={fechar} alt="" />
      </button>
      <S.Selecao>
        <h1>{ redux_lang === 'pt' ? `Selecione o idioma` :  `Select the language` }</h1>
        <div>
          <button onClick={() => redux_setLang('pt')}>
            <img src={brasil} alt="" />
          </button>
          <button onClick={() => redux_setLang('us')}>
            <img src={states} alt="" />
          </button>
        </div>
      </S.Selecao>
    </S.Content> 

    <S.Container>
      <S.LeftSide>
        <Link to="/"><img src={home} alt="Inicio"/></Link>
        <button onClick={() => setToggle(true)} >
          <img src={ redux_lang === 'pt' ? brasil : states } alt=""/>
        </button>
      </S.LeftSide>
      <S.Center>
        <Link to="/" > <img src={logo} alt="Logo"/></Link>
      </S.Center>
      <S.RightSide>
        <Link to={`/${ redux_user ? 'perfil' : 'logar' }`} ><img src={about} alt="Logar"/></Link>
      </S.RightSide>
    </S.Container>
    </>
  )
}

const mapStateToProps = state => ({
  redux_lang: state.lang,
  redux_user: state.user
})

// dispatch É A FUNCAO PRA RECEBER O NOVO ESTADO
const mapDispatchToProps = dispatch => ({
  redux_setLang(lang) {
    dispatch(LANGUAGE.call(lang))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);
