import axios from 'axios'

import {
  UTILS
} from './links.json'

export default axios.create({
  baseURL: UTILS.EMAIL.SEND,
  timeout: 10000
})

export const LOGO_URL = UTILS.EMAIL.LOGO

export const TOKEN = UTILS.EMAIL.TOKEN
