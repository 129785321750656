import styled from 'styled-components';

export const Container = styled.div`


`
export const Content = styled.div`
  padding: 24px 20px;

  > h1 {
    font-size: 16px;
    font-weight: bold; 
    color: #273294;
    padding-bottom: 10px;
  }

  a {
    text-decoration: none;
  }

  > span {
    text-align: center;
    display: block;
    color: #959595;
    font-weight: bold;
    margin-top: 50px;
  }
`