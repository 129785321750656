import numberFormatter from 'currency-formatter'

export const getString = ({ 
  message, fall_message = 'Ocorreu um erro'
})  => {
  // PREVINE DE DAR UM LOG NA TELA (TOAST NOTIFICATION | ALERT ...) DE UM VALOR QUE NÃO É STRING

  if (!message) {
    message = fall_message
  }

  return typeof message === 'string' ? message : typeof fall_message === 'string' ? fall_message : 'Ocorreu um erro'
}

export const submit_state = {
  done: false, // SE A REQUISICAO JÁ ACABOU
  req: false, // SE A FUNCAO DE REQUISIAO ESTÁ EM ANDAMENTO
  status: false // SE A REQUISICAO DEU SUCESSO OU ERRO
}

export const dateFormated = () => {

  const _d = new Date()

  const get = lang => _d.toLocaleString(lang, { weekday: 'long', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric' })
  
  return {
    pt: get('pt'),
    us: get('en')
  }
}

export const getPrice = (v) => {

  // return numberFormatter.format(+v, {
  //   code: 'BRL',
  //   locale: 'pt-BR'
  // })

  return (v || 0).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency'})

}

export const capitalize = v => v[0].toUpperCase() + v.substring(1)

export const getStatus = ({ status, lang = 'pt', manutencao }) => {
  switch (status) {
    case 1:
      return lang === 'pt' ? manutencao ? 'Concluído' : 'Entregue' : manutencao ? 'Concluded' : 'Delivered'
    case 2:
      return lang === 'pt' ? 'Processando' : 'Processing'
    case 3:
      return lang === 'pt' ? 'Aguardando' : 'Waiting'
    case 0:
      return lang === 'pt' ? 'Cancelado' : 'Canceled'
    default:
      return ''
  }
}

export const getQuery = query => Object.entries(query).filter(([_, value]) => String(value).length).map(([ key, value ]) => `${ key }=${ encodeURIComponent(value) }`).join('&')

export const getTimeString = (lang, { start, end }) => {
  if (start == end) {
    return lang === 'pt' ? 'Disponível 24h' : 'Avaliable 24 hours'
  }

  switch(lang) {
    case 'us':
      const amOrpm = (time) => {
        if (time >= 0 && time <= 12) {
          return 'am'
        } 

        return 'pm'
      }

      const getNumber = (time) => {
        if (time > 12) {
          return time - 12
        } 

        return time
      }

      return `Currently ${isAvailableTime(start, end) ? 'available' : 'unavailable'}`

      return `Available from ${ getNumber(start) }${ amOrpm(start) } to ${ getNumber(end) }${ amOrpm(end) }`
    default:
      return `${ isAvailableTime(start, end) ? 'Disponível' : 'Indisponível' } no momento`
  }
}

export const isAvailableTime = (start, end) => {
  if (start == end) return true

  const hours = new Date().getHours()

  const endAtNextDay = start > end

  if (endAtNextDay) {
    return hours >= start || hours < end
  } else {
    return hours >= start && hours < end
  }
}
