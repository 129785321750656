import styled from 'styled-components'

export const Container = styled.div``
export const Header = styled.div`
	padding: 24px 20px;

	> h1 {
		padding-bottom: 10px;
		font-size: 16px;
		font-weight: bold;
		color: #273294;

		a {
			font-size: 16px;
			font-weight: bold;
			color: #273294;
		}
		span {
			font-size: 12px;
			font-weight: 500;
		}
	}

	a {
		text-decoration: none;
	}

	.infos {
		padding: 20px 0;

		h4 {
			font-size: 12px;
			font-weight: normal;
			color: #444444;
			margin: 5px 0;

			span {
				font-weight: bold;
			}

			span.entregue {
				color: #273294;
			}

			span.processando {
				color: #008305;
			}

			span.aguardando {
				color: #3c86e3;
			}

			span.cancelado {
				color: #ff0000;
			}
		}
	}
`
export const Content = styled.div`
	padding: 0 20px;

	.pedidos {
		padding: 10px 0;

		.manutencao {
			row-gap: 20px;
		}

		.card {
			margin-bottom: 10px;
			width: 100%;
			background-color: #ffffff;
			border-radius: 5px;
			padding: 10px;
			box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.1);
			display: flex;
			flex-direction: column;

			h4 {
				font-size: 12px;
				font-weight: normal;
				color: #444444;
				padding-top: 5px;
			}

			.valor,
			span {
				display: block;
				font-size: 10px;
				color: #959595;
			}

			.valor {
				margin: 10px 0;
			}

			.observacao {
				margin: 15px 0;

				p {
					margin-top: 5px;
					font-size: 12px;
					font-weight: normal;
					color: #444444;
				}
			}
		}
	}
	.detalhes {
		> h4 {
			font-size: 12px;
			font-weight: normal;
			color: #444444;
			span {
				font-size: 14px;
				font-weight: bold;
			}
			padding: 2px 0;
		}
		.local,
		.observacao {
			padding: 10px 0;

			h4 {
				font-size: 12px;
				font-weight: normal;
				color: #444444;
				padding-top: 5px;
			}
			span {
				font-size: 10px;
				color: #959595;
			}
		}
		.observacao {
			padding: 20px 0;
		}
	}
	.acoes {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: 50px;

		> p {
			text-align: center;
			padding: 0 0 10px 0;
		}

		button {
			max-width: 500px;
			width: 100%;
			align-self: center;
			height: 40px;
			border-radius: 5px;
			outline: none;
			border: none;
			cursor: pointer;
			margin-bottom: 10px;
			font-size: 12px;
			font-weight: bold;
		}

		button.azul,
		button.verde {
			color: #fff;
		}

		button.azul {
			background-color: #3c86e3;
		}

		button.verde {
			background-color: #34b32b;
		}
	}
`
