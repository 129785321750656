import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import SOCKET from '../../services/socket'
import API, { endpointers } from '../../services/api'
import * as LOCALSTORAGE from '../../services/localStorage'
import { submit_state } from '../../utils'

import Header from '../../components/Home/Header';
import CardPedido from '../../components/MeusPedidos/CardPedido';
import * as S from './style'

function MeusPedidos({ redux_lang, redux_user }) {

  const [req, setReq] = useState({ ...submit_state, req: true })
  const [orders, setOrders] = useState([])

  const { push } = useHistory()

  useEffect(() => {
    if (redux_user) {
      SOCKET.on('atualizar_status_do_pedido', ({ _id, status, entregue } = {}) => {
        setOrders(olsState => olsState.map(order => order._id === _id ? { ...order, status, entregue } : order ))
      })

      SOCKET.on('apagar_pedido_receptor', ({ _id }) => {
        setOrders(oldState => oldState.map(order => order._id === _id ? false : order ).filter(v => !!v))
      })
    }
  }, [])

  const request = useCallback(async () => {
    try {
      setReq(oldState => ({
        ...oldState,
        req: true
      }))

      const { data } = await API(endpointers.get.order(redux_user?.IdHospede), { headers: { Authorization: LOCALSTORAGE.token.get() } })

      setOrders(data)
    } catch(e) {
      console.error(e)
    } finally {
      setReq({ ...submit_state })
    }
  }, [])

  useEffect(() => {
    request()
  }, [])

  if (!redux_user) {
    push('/logar', {
      redirect: 'meuspedidos'
    })
    return null
  }

  return (
    <S.Container>
      <Header/>
      <S.Content>
        <h1>{ redux_lang === 'pt' ? 'Meus pedidos' : 'My requests' }</h1>
        {
          req.req ? (
            <span>{ redux_lang === 'pt' ? 'Aguarde' : 'Wait' } ...</span>
          ) : orders.length ? orders.map(pedido => { 
            
            return (
              <Link to={{
                pathname: `/meuspedidos/detalhe`,
                query: {
                  data: pedido
                }
              }} key={ pedido._id } >
                <CardPedido 
                  data={ pedido }
                  pt={ redux_lang === 'pt' }
                />
              </Link>
            )
          }) : (
            <span>{ redux_lang === 'pt' ? 'Vazio' : 'Empty' }</span>
          )
        }
      </S.Content>
    </S.Container>
  )
}

const mapStateToProps = state => ({
  redux_lang: state.lang,
  redux_user: state.user
})

export default connect(mapStateToProps)(MeusPedidos)
