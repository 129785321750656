import React from 'react';
import * as S from './style'
import instagram from '../../../assets/images/Home/barraFixa/instagram.svg'
import facebook from '../../../assets/images/Home/barraFixa/facebook.svg'
import tripadvisor from '../../../assets/images/Home/barraFixa/tripadvisor.svg'

function BarraFixa(){
  return (
    <S.Container>
      <S.Barra>
        <a href="https://instagram.com/parkhotelrecife" target='_blank'>
          <img src={instagram} alt=""/>
        </a>
        <a href="https://facebook.com/parkhotelrecife" target='_blank'>
          <img src={facebook} alt=""/>
        </a>
        <a href="https://www.tripadvisor.com.br/Hotel_Review-g304560-d583377-Reviews-Park_Hotel-Recife_State_of_Pernambuco.html?m=19905" target='_blank'>
          <img src={tripadvisor} alt=""/>
        </a>
      </S.Barra>
    </S.Container>
  )
}

export default BarraFixa;