import styled from 'styled-components'

export const Container = styled.div`
	padding: 80px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		font-size: 18px;
		font-weight: bold;
		color: #fff;
		margin-bottom: 15px;
	}
	form {
		width: 100%;
		.group {
			padding-top: 15px;
			label {
				font-size: 12px;
				display: block;
				color: #fff;
			}
			input {
				width: 100%;
				height: 40px;
				border-radius: 5px;
				outline: none;
				border: none;
				margin-top: 10px;
				padding: 0 10px;
				font-size: 12px;
				color: #444444;
			}
		}
		button {
			width: 100%;
			height: 40px;
			background-color: #3c86e3;
			color: #fff;
			font-size: 12px;
			font-weight: bold;
			outline: none;
			border: none;
			cursor: pointer;
			margin-top: 30px;
		}
	}
	.logado {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		h1 {
			font-size: 26px;
			margin-bottom: 4px;
		}
		h2 {
			font-size: 16px;
			font-weight: 100;
			color: #fff;
		}
		a.button {
			width: 100%;
			margin: 100px 0 20px;
		}
		button {
			width: 100%;
			height: 40px;
			background-color: #3c86e3;
			color: #fff;
			font-size: 12px;
			font-weight: bold;
			outline: none;
			border: none;
			cursor: pointer;
		}
		button.sair {
			background-color: transparent;
			border: 1px solid #fff;
			margin-top: 0;
		}
	}
`
