import styled from 'styled-components'

export const Container = styled.div`
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #273294;
  height: 100vh;

  > .fechar {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  h1{
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
    margin-bottom: 15px;
  }
  
  .logado{
    width: 100%;
    display: flex;
    flex-direction:column;
    align-items: center;

    h1{
      font-size: 26px;
      margin-bottom: 4px;
    }
    h2{
      font-size: 16px;
      font-weight: 100;
      color: #FFF;
    }

    .acoes {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;
      row-gap: 30px;
      width: 100%;
      
      > *, > * > * {
        width: 100%;
        max-width: 335px;
        height: 40px;
        background-color:#3C86E3;
        color: #FFF;
        font-size: 12px;
        font-weight: bold;
        outline: none;
        border: none;
        cursor: pointer;
      } 

      button.sair {
        background-color: transparent;
        border: 1px solid #FFF;
        margin-top: 0;
      }
    }
    
  }
`