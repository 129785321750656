import React, { useState } from 'react';
import * as S from './style'

import lixeira from '../../../../../assets/images/Home/Carrinho/trash.svg'

function ItemCarrinho({ 
  titulo, medida = '', quantidade, valor = '', observacao = '',
  _handleQtd, _handleTrash, pt
}) {

  return (
    <S.Container>
      <S.Card>
        <h1>{ titulo } { medida.length ? `- ${ medida }` : '' }</h1>
        {
          observacao.length ? (
            <div className='observacao'>
              <span>{ pt ? 'Observação': 'Note' }</span>
              <h1>{ observacao }</h1>
            </div>
          ) : null
        }
        <S.Quantidade>
          <S.Box>
            <div>
              <button disabled={ quantidade === 1 } onClick={_ => _handleQtd()}> - </button>
              <input disabled value={quantidade}/>
              <button onClick={_ => _handleQtd(true)}> + </button>
            </div>
            <button onClick={ _handleTrash }>
              <img src={lixeira} alt="lixeira" />
            </button>
          </S.Box>
        <h1>{ valor }</h1>
        </S.Quantidade>
      </S.Card>
    </S.Container>
  )
}

export default ItemCarrinho;
