import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  border-radius: 5px;
  box-shadow: 0px 5px 14px rgba(0,0,0,0.1);
  margin: 10px 0;
  column-gap: 20px;
  `
export const LeftSide = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  `

export const Cicle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(45deg, rgba(54,0,141,1) 0%, rgba(176,8,131,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  `

export const RightSide = styled.div`
  flex: 1;
  max-width: 500px;

  h1 {
    font-size: 16px;
    color: #273294;
    font-weight: bold;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    color: #959595;
  }
`