import React from 'react';
import * as S from './styles'

//Components
import Header from '../../components/Home/Header'
import FaleConosco from '../../components/FaleConosco'



function Contato() {
  return (
    <S.Container>
      <Header/>
      <FaleConosco/>
    </S.Container>
  )
}

export default Contato;
