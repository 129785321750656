import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Card = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #E4E4E4;
  h1{
    font-size: 12px;
    color: #444444;
    font-weight: 500;
  }

  .observacao {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    margin: 15px 0;

    span {

    }
  }
`

export const Quantidade = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const Box = styled.div`
  display: flex;
  align-items: baseline;
  >div{
    margin-top: 10px;
    button{
      height: 30px;
      width: 25px;
      font-size: 16px;
      color: #959595;
      border: none;
      outline: none;
      cursor: pointer;
      background: transparent;
    }
    input{
      width: 30px;
      height: 30px;
      border: none;
      text-align: center;
      outline: none;
    }
  }
  >button{
    height: 30px;
    margin: 0 10px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    img{
      height: 15px;
    }
  }
`
