import { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom'

import EMAIL, { TOKEN, LOGO_URL } from '../../services/email'
import { submit_state } from '../../utils/index'

import * as S from './style'
import * as SU from '../../utils/styles'

import sucess from '../../assets/images/FaleConosco/Sucesso/sucesso.svg'
import seta from '../../assets/images/FaleConosco/Sucesso/seta-baixo.png'
import * as UTILS from '../../utils';

const initial_data = {
  assunto: {
    pt: '', us: ''
  },
  mensagem: ''
}

function FaleConosco({ redux_lang, redux_user }) {
  const [submit, setSubmit] = useState({ ...submit_state })
  const [isToggle, setToggle] = useState(false)
  const [data, setData] = useState({ ...initial_data })

  const { addToast } = useToasts()
  const { push } = useHistory()

  const _handleSubmit = useCallback(async (e) => {
    e.preventDefault()

    if (submit.req) return

    
    if (data.assunto.pt.length) {
      setSubmit({ ...submit_state, req: true })

      let status = true

      try {
        const { data: dataFromApi } = await EMAIL(`?${ UTILS.getQuery({
          target: 'entre-em-contato', 
          titulo:  redux_lang === 'pt' ? 'Entre em contato' : 'Contact Us', 
          remetente: `${ redux_user.Nome } ${ redux_user.Sobrenome }`, apartamento: redux_user.CodUH,
          mensagem: data.mensagem, assunto: data.assunto[redux_lang], lang: redux_lang,
          logo: LOGO_URL, token: TOKEN
        }) }`)

        console.log(dataFromApi)
  
        if (!dataFromApi.ok) {
          throw new Error(dataFromApi.message)
        }

        setData({ ...initial_data })

      } catch(e) {

        setData({ ...initial_data })
        status = false
        console.error(e)
        addToast(redux_lang === 'pt' ? 'Ocorreu um erro' : 'An error has occurred', {
          appearance: 'error',
          autoDismiss: true
        })
      } finally {
        setSubmit({
          req: false, done: true, status
        })
      }
    } else {
      addToast(redux_lang === 'pt' ? 'Selecione o assunto' : 'Select subject', {
        autoDismiss: true
      })
    }
  }, [data, submit, redux_user])

  useEffect(() => {
    if (submit.done && submit.status) {
      setToggle(true)
    }
  }, [submit])

  if (!redux_user) {
    push('/logar', {
      redirect: 'entre-em-contato'
    })
    return null
  }

  return (
    <>
      <S.Container onSubmit={ _handleSubmit }>
        <h1>{ redux_lang === 'pt' ? 'Entre em contato' : 'Contact Us' }</h1>
        <select name="assunto" id="assunto" 
          value={ JSON.stringify(data.assunto) } onChange={({ target: { value } }) => setData(oldState => ({ ...oldState, assunto: JSON.parse(value) }))}>
          <option value="">{ redux_lang === 'pt' ? 'Selecione o assunto' : 'Select subject' }...</option>
          <option value={ JSON.stringify({ pt: 'Crítica', us: 'Review' }) }>{ redux_lang === 'pt' ? 'Crítica' : 'Review' }</option>
          <option value={ JSON.stringify({ pt: 'Dúvidas', us: 'Doubts' }) }>{ redux_lang === 'pt' ? 'Dúvidas' : 'Doubts' }</option>
          <option value={ JSON.stringify({ pt: 'Sugestão', us: 'Suggestion' }) }>{ redux_lang === 'pt' ? 'Sugestão' : 'Suggestion' }</option>
        </select>
        <textarea required 
          placeholder={ redux_lang === 'pt' ? 'Escreva sua mensagem' : 'Write your message' }
          value={ data.mensagem } onChange={({ target: { value: mensagem } }) => setData(oldState => ({ ...oldState, mensagem }))}
        ></textarea>
        <button type='submit' disabled={ submit.req }>{ submit.req ? `${ redux_lang === 'pt' ? 'Enviando' : 'Sending'} ...` : redux_lang === 'pt' ? 'Enviar' : 'Send' }</button>
      </S.Container>

      <SU.Modal className={isToggle ? 'expandido' :  'escondido'}>
        <S.Conteudo>
          <S.Cabecalho>
            <button onClick={_ => {
              setToggle(false)
              setSubmit({ ...submit_state })
            }}>
              <img src={seta} alt="" />
            </button>
            <h1>{ redux_lang === 'pt' ? 'Entre em contato' : 'Contact Us' }</h1>
          </S.Cabecalho>
          <SU.ModalSuccessResponse>
              <img src={sucess} alt="" />
              <h1>{ redux_lang === 'pt' ? 'Mensagem enviada com sucesso' : 'Message sent successfully' }!</h1>
              <span>{ redux_lang === 'pt' ? 'Em breve, retornaremos seu contato' : 'We will get back to you soon.' }.</span>
              <button
                onClick={_ => {
                  setToggle(false)
                  setSubmit({ ...submit_state })
                }}
              >{ redux_lang === 'pt' ? 'Voltar' : 'Back' }</button>
          </SU.ModalSuccessResponse>
        </S.Conteudo>
      </SU.Modal>
    </>
  );
}

const mapStateToProps = state => ({
  redux_lang: state.lang,
  redux_user: state.user
})

export default connect(mapStateToProps)(FaleConosco)