const Instance = (key) => ({
	set: (v) => localStorage.setItem(key, v),
	get: () => localStorage.getItem(key),
	remove: () => localStorage.removeItem(key),
})

export const token = Instance('park-hotel-app-token')

export const admUser = Instance('park-hotel-adm-user')

export const lang = Instance('park-hotel-app-lang')
