import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import CONFIG from '../../services/redux/reducer/actions/config'
import USER from '../../services/redux/reducer/actions/user'

import API, { endpointers } from '../../services/api'
import * as LOCALSTORAGE from '../../services/localStorage'
import SOCKET from '../../services/socket'

import * as S from './styles'

//Components
import BarraFixa from '../../components/Home/BarraFixa'
import BoasVindas from '../../components/Home/BoasVindas'
import CardsHome from '../../components/Home/CardsHome'
import Header from '../../components/Home/Header'

//imagens
import faleConosco from '../../assets/images/Home/listItensHome/faleConosco.svg'
import governanca from '../../assets/images/Home/listItensHome/governanca.svg'
import info_cidade from '../../assets/images/Home/listItensHome/info_cidade.svg'
import info_hotel from '../../assets/images/Home/listItensHome/info_hotel.svg'
import manutencao from '../../assets/images/Home/listItensHome/manutencao.svg'
import quarto from '../../assets/images/Home/listItensHome/servicoQuarto.svg'

function Home({
	redux_user,
	redux_config,
	redux_lang,
	redux_setUser,
	redux_setConfig,
}) {
	const { addToast } = useToasts()

	const reAuth = useCallback(async () => {
		try {
			const { data: dataFromApi } = await API(endpointers.reconnect, {
				headers: { Authorization: LOCALSTORAGE.token.get() },
			})

			if (!dataFromApi.ok) {
				throw new Error(dataFromApi.message)
			}

			redux_setUser({ ...dataFromApi.data, token: dataFromApi.token })

			LOCALSTORAGE.token.set(dataFromApi.token)

			SOCKET.connect()
			addToast(
				`${redux_lang === 'pt' ? 'Bem-vindo(a)' : 'Welcome'} ${
					dataFromApi.data.Nome + ' ' + dataFromApi.data.Sobrenome
				}`,
				{ autoDismiss: true }
			)
		} catch (e) {
			console.error(e)
			LOCALSTORAGE.token.remove()
			addToast(
				redux_lang === 'pt'
					? 'Não foi possível reconectar'
					: 'Could not reconnect',
				{ autoDismiss: true }
			)
		}
	}, [redux_lang])

	useEffect(() => {
		const userAdm = LOCALSTORAGE.admUser.get()

		if (!redux_user && userAdm) {
			//       {
			//     "_id": "6127901109a42be9f87315eb",
			//     "username": "capiba",
			//     "username_key": "capiba",
			//     "email": "cpbx@capiba.com.br",
			//     "level": 1,
			//     "created_at": 1629982737298,
			//     "token": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7ImFkbSI6dHJ1ZSwiX2lkIjoiNjEyNzkwMTEwOWE0MmJlOWY4NzMxNWViIiwibGV2ZWwiOjEsInVzZXJuYW1lIjoiY2FwaWJhIn0sImV4cCI6MTY4ODUxODc2NywiaWF0IjoxNjg3OTEzOTY3fQ.YQaePQ2eD9znMqYdhMMlpA4OZtoTnPdEATunTsZw5Qc"
			// }

			const user = JSON.parse(userAdm)

			LOCALSTORAGE.token.set(user.token)

			const data = {
				IdHospede: user._id,
				Sobrenome: user.username,
				CodUH: 0,
				Nome: '',
				...user,
				token: user.token,
				adm: true,
			}

			redux_setUser(data)

			SOCKET.connect()
			addToast(`Bem-vindo(a) ${user.username}`, { autoDismiss: true })
		} else if (!redux_user && LOCALSTORAGE.token.get()?.length) {
			addToast(`${redux_lang === 'pt' ? 'Reconectando' : 'Reconnecting'}...`, {
				autoDismiss: true,
				appearance: 'info',
			})
			reAuth()
		}
	}, [redux_user])

	const getConfig = useCallback(async () => {
		if (!redux_config) {
			try {
				const { data: dataFromApi } = await API(endpointers.get.config, {
					headers: { Authorization: LOCALSTORAGE.token.get() },
				})

				if (dataFromApi.ok) {
					redux_setConfig(dataFromApi.data)
				}
			} catch (e) {
				alert(
					redux_lang === 'pt'
						? 'Ocorreu um erro ao buscar configurações'
						: 'An error occurred while fetching settings'
				)
				window.location.reload()
			}
		}
	}, [redux_config, redux_lang])

	useEffect(() => {
		getConfig()
	}, [])

	return (
		<S.Container>
			<Header />
			<BoasVindas user={redux_user} pt={redux_lang === 'pt'} />
			<S.ListItem>
				{redux_user?.adm ? (
					<>
						<Link
							to={{
								pathname: `/${redux_user ? 'sevicos-de-manutencao' : 'logar'}`,
								query: {
									redirect: 'sevicos-de-manutencao',
								},
							}}
						>
							<CardsHome
								imagem={manutencao}
								titulo={redux_lang === 'pt' ? 'Manutenção' : 'Maintenance'}
								conteudo={`${
									redux_lang === 'pt'
										? 'Solicitações de serviço de manutenção no apartamento'
										: 'Apartment maintenance service requests'
								}.`}
							/>
						</Link>
						<Link
							to={{
								pathname: `/${redux_user ? 'sevicos-de-manutencao' : 'logar'}`,
								query: {
									_schema: 'tecnologia',
								},
							}}
						>
							<CardsHome
								imagem={manutencao}
								titulo={redux_lang === 'pt' ? 'TI' : 'IT'}
								conteudo={`${
									redux_lang === 'pt'
										? 'Solicitações de serviço de manutenção o departamento de TI'
										: 'Maintenance service requests the IT department'
								}.`}
							/>
						</Link>
					</>
				) : (
					<>
						<Link
							to={{
								pathname: `/${
									redux_user ? 'servicos-do-restaurante' : 'logar'
								}`,
								query: {
									redirect: 'servicos-do-restaurante',
								},
							}}
						>
							<CardsHome
								imagem={quarto}
								titulo={redux_lang === 'pt' ? 'Restaurante' : 'Restaurant'}
								conteudo={`${
									redux_lang === 'pt'
										? 'Confira todos os itens do nosso cardápio e faça seu pedido'
										: 'Check out all the items on our menu and place your order'
								}.`}
							/>
						</Link>
						<Link
							to={{
								pathname: `/${redux_user ? 'servicos-de-governanca' : 'logar'}`,
								query: {
									redirect: 'servicos-de-governanca',
								},
							}}
						>
							<CardsHome
								imagem={governanca}
								titulo={redux_lang === 'pt' ? 'Governança' : 'Governance'}
								conteudo={`${
									redux_lang === 'pt'
										? 'Solicitações de lençol, cobertor, travesseiro, entre outros'
										: 'Sheet, blanket, pillow requests, among others'
								}.`}
							/>
						</Link>
						<Link
							to={{
								pathname: `/${redux_user ? 'sevicos-de-manutencao' : 'logar'}`,
								query: {
									redirect: 'sevicos-de-manutencao',
								},
							}}
						>
							<CardsHome
								imagem={manutencao}
								titulo={redux_lang === 'pt' ? 'Manutenção' : 'Maintenance'}
								conteudo={`${
									redux_lang === 'pt'
										? 'Solicitações de serviço de manutenção no apartamento'
										: 'Apartment maintenance service requests'
								}.`}
							/>
						</Link>
						<Link
							to={{
								pathname: `/${redux_user ? 'informacoes-do-hotel' : 'logar'}`,
								query: {
									redirect: 'informacoes-do-hotel',
								},
							}}
						>
							<CardsHome
								imagem={info_hotel}
								titulo={
									redux_lang === 'pt'
										? 'Informações do hotel'
										: 'Hotel information'
								}
								conteudo={`${
									redux_lang === 'pt'
										? 'Informações gerais'
										: 'General information'
								}.`}
							/>
						</Link>
						<Link
							to={{
								pathname: `/${redux_user ? 'informacoes-da-cidade' : 'logar'}`,
								query: {
									redirect: 'informacoes-da-cidade',
								},
							}}
						>
							<CardsHome
								imagem={info_cidade}
								titulo={
									redux_lang === 'pt'
										? 'Informações da Cidade'
										: 'City Information'
								}
								conteudo={`${
									redux_lang === 'pt'
										? 'Dicas de passeios, bares, restaurantes, entre outros'
										: 'Tips for tours, bars, restaurants, among others'
								}.`}
							/>
						</Link>
						<Link
							to={{
								pathname: `/${redux_user ? 'entre-em-contato' : 'logar'}`,
								query: {
									redirect: 'entre-em-contato',
								},
							}}
						>
							<CardsHome
								imagem={faleConosco}
								titulo={redux_lang === 'pt' ? 'Entre em contato' : 'Contact us'}
								conteudo={`${
									redux_lang === 'pt'
										? 'Enviando sugestões, dúvidas e/ou críticas'
										: 'Sending suggestions, questions and/or criticisms'
								}.`}
							/>
						</Link>
					</>
				)}
			</S.ListItem>
			<BarraFixa />
		</S.Container>
	)
}

// PEGA P ESTADO DO REDUX E MANDA COMO PROPS PARA O COMPONENTE
const mapStateToProps = (state) => ({
	redux_user: state.user,
	redux_lang: state.lang,
	redux_config: state.config,
})

const mapDispatchToProps = (dispatch) => ({
	redux_setUser(data) {
		dispatch(USER.call(data))
	},
	redux_setConfig(data) {
		dispatch(CONFIG.call(data))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
