import React from 'react';
import * as S from './styles'


function CardsHome({imagem, titulo, conteudo = ''}){
  return (
    <S.Container>
      <S.LeftSide>
        <S.Cicle>
          <img src={imagem} alt=""/>
        </S.Cicle>
      </S.LeftSide>
      <S.RightSide>
        <h1>{titulo}</h1>
        <p>{conteudo}</p>
      </S.RightSide>
    </S.Container>
  )
}
export default CardsHome;