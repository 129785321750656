import React, { useCallback, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications'

import * as SS from '../styles'
import * as SU from '../../../../utils/styles'

import API, { endpointers } from '../../../../services/api'
import * as LOCALSTORAGE from '../../../../services/localStorage'

import Header from '../../Header'
import Item from '../Item';

//imagem
import agua from '../../../../assets/images/MenuItens/agua.png'
import lupa from '../../../../assets/images/MenuItens/lupa.svg'
import seta from '../../../../assets/images/MenuItens/Carrinho/seta-baixo.png'
import cart from '../../../../assets/images/MenuItens/cart.svg'

function MenuItens({ redux_lang }) {
  const [requesting, setRequesting] = useState(false)
  const [term, setTerm] = useState('')
  const [itemExpanded, setItemExpanded] = useState()
  const [detailsOpenned, setDetailsOpenned] = useState(false)
  const [ready, setReady] = useState(false)
  const [list, setList] = useState([])
  const { addToast } = useToasts()

  const searchInput = useRef()

  const request = useCallback(async (name, force) => {

    if (requesting && !force) return

    setRequesting(true)

    try {
      const { data: dataFromApi } = await API.get(endpointers.get.catalog({ nome: name, lang: redux_lang }), { headers: { Authorization: LOCALSTORAGE.token.get() } })
  
      if (!dataFromApi.ok) {
        return addToast(dataFromApi.message, {
          appearance: 'error',
          autoDismiss: true
        })
      }

      setList(dataFromApi.data)
    } catch(e) {

    } finally {
      setRequesting(false)
      !ready && setReady(true)
    }

  }, [requesting, redux_lang])

  useEffect(() => {
    request(term)
  }, [])

  useEffect(() => {
    if (ready) {
      request(term)
    }
  }, [term])

  useEffect(() => {
    if (itemExpanded) {
      setDetailsOpenned(true)
    }
  }, [itemExpanded])

  useEffect(() => {
    if (!detailsOpenned) {
      setTimeout(() => {
        setItemExpanded()
      }, 500)
    }
  }, [detailsOpenned])

  const _handleDoSearch = useCallback((e) => {
    e.preventDefault()
    
    if (searchInput.current) {
      if (searchInput.current.value === term) {
        request(term)
      } else {
        setTerm(searchInput.current.value)
      }
    }
  }, [searchInput, term])

  return(
    <SS.Container>
      <Header />
      <SS.TopoContainer>
        <SS.Topo>
          <h1>{ redux_lang === 'pt' ? 'Informações do hotel' : 'Hotel information' } { term.length ? `- ${ term }` : '' }</h1>
        </SS.Topo>
        <SS.Filters>
          <form onSubmit={ _handleDoSearch }>
            <input 
              ref={ searchInput }
              type="text"
              placeholder={ redux_lang === 'pt' ? 'Buscar' : 'Search' }
            />
            <button type='submit'>
              <img src={lupa} alt=""/>
            </button>
          </form>
        </SS.Filters>
      </SS.TopoContainer>

      <SS.Cards>
        {
          requesting || !list.length ? (
            <SS.EmptyOrLoading>
              <span>
                {
                  requesting ? redux_lang === 'pt' ? 'Por favor, aguarde ...' : 'Please, wait ...'
                    : redux_lang === 'pt' ? 'Nenhum item' : 'No items'
                }
              </span>
            </SS.EmptyOrLoading> 
          ) : list
          .sort((a, b) => a.titulo[redux_lang].localeCompare(b.titulo[redux_lang]))
          .map(item => (
            <Item 
              key={ item._id }
              titulo={ `${ item.titulo[redux_lang] }` } 
              descricao={ item.descricao[redux_lang].length > 40 ? `${ item.descricao[redux_lang].substr(0, 40) }...` : item.descricao[redux_lang] } 
              imagem={ item.url } 
              _handleExpand={() => setItemExpanded(item) }
            />
          ))
        }
      </SS.Cards>

      <SU.Modal className={detailsOpenned ? 'expandido' : 'escondido'}>
        <SS.Conteudo>
          <SS.Cabecalho>
            <button onClick={() => setDetailsOpenned(false)}>
              <img src={seta} alt="" />
            </button>
            <h1>{ redux_lang === 'pt' ? 'Detalhes do item' : 'Item Details'}</h1>
          </SS.Cabecalho>
          <SS.Imagem bg={ itemExpanded?.url } />
          <SS.Content>
            <SS.Infos>
              <h1>{ itemExpanded?.titulo[redux_lang] }</h1>
              <div className='descricao' dangerouslySetInnerHTML={{ __html: itemExpanded?.descricao[redux_lang] || '' }} />
            </SS.Infos>
          </SS.Content>

        </SS.Conteudo>
      </SU.Modal>

    </SS.Container>
  )
}

const mapStateToProps = state => ({
  redux_lang: state.lang
})

export default connect(mapStateToProps)(MenuItens)