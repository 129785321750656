import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #273294;

  > h1 {
    padding-top: 40px;
    color: #FFF;
    text-align: center;
  }

  a.fechar {

    > button {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
`