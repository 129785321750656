import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  background-color: #F7F7F7;
  height: calc(100vh - 50px);
  >h1{
    font-size: 16px;
    color: #273294;
    padding-bottom: 20px;
  }
  >select{
    background-color: #E4E4E4;
    outline: none;
    border: none;
    height: 40px;
    width: 100%;
    padding-left: 10px;
    font-size: 12px;
    color: #959595;
    border-radius: 5px;
  }
  >textarea{
    width: 100%;
    height: 100px;
    background-color: #FFF;
    font-size: 12px;
    color: #959595;
    box-shadow: 0px 5px 14px rgba(0,0,0, 0.1);
    margin: 10px 0 50px;
    outline: none;
    border: none;
    resize: none;
    border-radius: 5px;
    padding: 10px;
  }
  >button{
    width: 100%;
    height: 40px;
    background-color: #3C86E3;
    border: none;
    outline: none;
    cursor: pointer;
    color: #FFF;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
  }
`
export const Conteudo = styled.div`
  width: 100%;
  padding: 14px 20px;
  height: inherit;
  background-color: #FFF;
  position: relative;
  top: 50px;
  overflow: auto;
  border-radius: 5px 5px 0;
  bottom: 0;
  >span{
    font-size: 12px;
    color: #959595;
  }
`

export const Sucesso = styled.div`
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: #FFF;
  bottom: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  transition: bottom ease-in-out 0.5s;
  &.expandido{
    transition: bottom ease-in-out 0.5s;
    bottom: 0;
  }
  &.escondido{
    transition: bottom ease-in-out 0.5s;
    bottom: -100vh;
  }
  
`

export const Cabecalho = styled.div`
  position: relative;
  display: flex;
  >h1{
    margin: 0 auto;
    font-size: 16px;
    color: #273294;
  }
  button{
    position: absolute;
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;
  }
`

export const Mensagem = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img{
    max-width: 80px;
    margin-top: 50px;
  } 
  h1{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    max-width: 185px;
    color: #444444;
    margin-top: 20px;
  }
  span{
    margin-top: 20px;
    font-size: 12px;
    color: #444444;
  }
`
