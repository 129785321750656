import styled from "styled-components";
import { UTILS } from "../../../services/links.json";

export const TopoContainer = styled.div`
  background-color: #f7f7f7;
  padding: 0 20px;
`;
export const Topo = styled.div`
  margin: 24px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  h1 {
    font-size: 16px;
    font-weight: bold;
    color: #273294;
  }
  
  > h2 {
    font-size: 16px;
    font-weight: bold;
    color: #273294;
  }
`;

export const EmptyOrLoading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;

  span {
    text-align: center;
  }
`;

export const Filters = styled.div`
  > * {
    display: flex;
    position: relative;

    input {
      width: 100%;
      border-radius: 5px;
      height: 40px;
      outline: none;
      border: none;
      padding: 0 10px;
      font-size: 12px;
      margin-bottom: 10px;
    }

    button {
      background: transparent;
      outline: none;
      border: none;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    select {
      width: 100%;
      border-radius: 5px;
      height: 40px;
      background-color: #e4e4e4;
      outline: none;
      border: none;
      padding: 0 10px;
      font-size: 12px;
      margin-bottom: 30px;
    }
  }
`;

export const Container = styled.div`
  max-height: 100vh;
  overflow-y: hidden;
`;
export const Cards = styled.div`
  height: calc(100vh - 250px);
  overflow-y: scroll;
  padding: 0 20px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 80px;
    padding-right: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #959595;
    border-radius: 2px;
  }

  button {
    width: 100%;
    outline: none;
    border: none;
    cursor: pointer;
  }
`;

//Detalhe itens

export const Conteudo = styled.div`
  width: 100%;
  padding: 14px 0;
  height: inherit;
  background-color: #fff;
  position: relative;
  top: 50px;
  overflow: auto;
  border-radius: 5px 5px 0;
  bottom: 0;
  > span {
    font-size: 12px;
    color: #959595;
  }
`;

export const Cabecalho = styled.div`
  padding: 0 20px;
  position: relative;
  display: flex;
  padding-bottom: 17px;
  > h1 {
    font-size: 16px;
    color: #273294;
    margin: 0 auto;
  }
  button {
    position: absolute;
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;
    width: max-content;
  }
`;

export const Imagem = styled.div`
  background-image: url(${({ bg }) => bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 150px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  > button {
    align-self: center;
    max-width: 500px;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
    margin-top: 20px;
  }
  button.azul {
    background-color: #3c86e3;
    color: #ffffff;
  }
`;

export const Infos = styled.div`
  > h1 {
    font-size: 18px;
    font-weight: bold;
    color: #444444;
    padding: 20px 0 9px;
  }

  .descricao > p,
  > p {
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    color: #959595;
    padding-bottom: 16px;

    span {
      color: #959595;
      font-weight: bold;
    }
  }

	.descricao {
		margin-bottom: 15px;
		
		> p {
			padding-bottom: 5px;
		}
	}

  > span {
    color: #444444;
    font-size: 14px;
  }

  > form {
    padding-top: 40px;
    textarea {
      width: 100%;
      padding: 10px;
      font-size: 12px;
      resize: none;
      border: none;
      border-radius: 5px;
      height: 60px;
      color: #959595;
      background-color: #e4e4e4;
    }
  }

  .acoes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    > h1 {
      font-size: 14px;
      font-weight: 500;
      > span {
        font-size: 16px;
        font-weight: bold;
      }
    }
    > div {
      display: flex;
      border: 1px solid #e4e4e4;
      button {
        height: 30px;
        width: 25px;
        font-size: 16px;
        color: #959595;
        border: none;
        outline: none;
        cursor: pointer;
        background: transparent;
      }
      input {
        width: 30px;
        height: 30px;
        border: none;
        text-align: center;
        outline: none;
      }
    }
  }
`;
