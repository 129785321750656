import React, { useCallback, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications'

import * as SS from '../styles'
import * as SU from '../../../../utils/styles'

import API, { endpointers } from '../../../../services/api'
import * as LOCALSTORAGE from '../../../../services/localStorage'

import Header from '../../Header'
import Item from '../Item';

//imagem
import agua from '../../../../assets/images/MenuItens/agua.png'
import lupa from '../../../../assets/images/MenuItens/lupa.svg'
import seta from '../../../../assets/images/MenuItens/Carrinho/seta-baixo.png'
import cart from '../../../../assets/images/MenuItens/cart.svg'

function MenuItens({ redux_lang }) {
  const [requesting, setRequesting] = useState(false)
  const [term, setTerm] = useState('')
  const [itemExpanded, setItemExpanded] = useState()
  const [detailsOpenned, setDetailsOpenned] = useState(false)
  const [category, setCategory] = useState('')
  const [ready, setReady] = useState(false)
  const [list, setList] = useState([])
  const [categories, setCategories] = useState([])

  const { addToast } = useToasts()
  const searchInput = useRef()

  const getCategories = useCallback(async () => {
    const { data: dataFromApi } = await API(endpointers.get.category({ _schema: 'concierge' }))

    if (dataFromApi.ok) {
      setCategories(dataFromApi.data)
    }

  }, [])

  useEffect(() => {
    getCategories()
  }, [])

  const request = useCallback(async ({ name, categoria_id, force }) => {

    if (requesting && !force) return

    setRequesting(true)

    try {
      const { data: dataFromApi } = await API.get(endpointers.get.concierge({ nome: name, categoria_id, lang: redux_lang }), { headers: { Authorization: LOCALSTORAGE.token.get() } })

      if (!dataFromApi.ok) {
        return addToast(dataFromApi.message, {
          appearance: 'error',
          autoDismiss: true
        })
      }

      setList(dataFromApi.data)
    } catch(e) {

    } finally {
      setRequesting(false)
      !ready && setReady(true)
    }

  }, [requesting, ready, redux_lang])

  useEffect(() => {
    request({ categoria_id: category, name: term })
  }, [])

  useEffect(() => {
    if (ready) {
      request({ categoria_id: category, name: term })
    }
  }, [category, term])

  useEffect(() => {
    if (itemExpanded) {
      setDetailsOpenned(true)
    }
  }, [itemExpanded])

  useEffect(() => {
    if (!detailsOpenned) {
      setTimeout(() => {
        setItemExpanded()
      }, 500)
    }
  }, [detailsOpenned])

  const _handleDoSearch = useCallback((e) => {
    e.preventDefault()
    
    if (searchInput.current) {
      if (searchInput.current.value === term) {
        request({ categoria_id: category, name: term })
      } else {
        setTerm(searchInput.current.value)
      }
    }
  }, [searchInput, term, category])

  return(
    <SS.Container>
      <Header />
      <SS.TopoContainer>
        <SS.Topo>
          <h1>{ redux_lang === 'pt' ? 'Informações da Cidade' : 'City Information' } { term.length ? `- ${ term }` : '' }</h1>
        </SS.Topo>
        <SS.Filters>
          <form onSubmit={ _handleDoSearch }>
            <input 
              ref={ searchInput }
              type="text"
              placeholder={ redux_lang === 'pt' ? 'Buscar' : 'Search' }
            />
            <button type='submit'>
              <img src={lupa} alt=""/>
            </button>
          </form>
          <div>
            <select name="categoria" id="categoria" value={ category } onChange={({ target: { value } }) =>  setCategory(value)}>
              <option value="">{ redux_lang === 'pt' ? 'Selecione uma categoria' : 'Select a category' }…</option>
              {
                categories
                .sort((a, b) => a.texto[redux_lang].localeCompare(b.texto[redux_lang]))
                .map(({ _id, texto }) => (
                  <option value={ _id }>{ texto[redux_lang] }</option>
                ))
              }
            </select>
          </div>
        </SS.Filters>
      </SS.TopoContainer>

      <SS.Cards>
        {
          requesting || !list.length ? (
            <SS.EmptyOrLoading>
              <span>
                {
                  requesting ? redux_lang === 'pt' ? 'Por favor, aguarde ...' : 'Please, wait ...'
                    : redux_lang === 'pt' ? 'Nenhum item' : 'No items'
                }
              </span>
            </SS.EmptyOrLoading>
          ) : list
          .sort((a, b) => a.titulo[redux_lang].localeCompare(b.titulo[redux_lang]))
          .map(item => (
            <Item 
              key={ item._id }
              titulo={ `${ item.titulo[redux_lang] }` } 
              descricao={ item.descricao[redux_lang].length > 40 ? `${ item.descricao[redux_lang].substr(0, 40) }...` : item.descricao[redux_lang] } 
              imagem={ item.url } 
              _handleExpand={() => setItemExpanded(item) }
            />
          ))
        }
      </SS.Cards>

      <SU.Modal className={detailsOpenned ? 'expandido' : 'escondido'}>
        <SS.Conteudo>
          <SS.Cabecalho>
            <button onClick={() => setDetailsOpenned(false)}>
              <img src={seta} alt="" />
            </button>
            <h1>{ redux_lang === 'pt' ? 'Detalhes do item' : 'Item Details'}</h1>
          </SS.Cabecalho>
          <SS.Imagem bg={ itemExpanded?.url } />
          <SS.Content>
            <SS.Infos>
              <h1>{ itemExpanded?.titulo[redux_lang] }</h1>
              <div className='descricao' dangerouslySetInnerHTML={{ __html: itemExpanded?.descricao[redux_lang] || '' }} />
              {
                (itemExpanded?.endereco['pt'].length || itemExpanded?.endereco['us'].length) ? (
                  <p><span>{ redux_lang === 'pt' ? 'Endereco' : 'Address' }:</span> { itemExpanded?.endereco[redux_lang].length ? itemExpanded?.endereco[redux_lang] : itemExpanded?.endereco[redux_lang === 'us' ? 'pt' : 'us'] }</p>
                ) : null
              }
              {
                itemExpanded?.distancia.length ? (
                  <p><span>{ redux_lang === 'pt' ? 'Distãncia do hotel' : 'Distance from the hotel' }:</span> { itemExpanded?.distancia }</p>
                ) : null
              }
              {
                itemExpanded?.telefone.length ? (
                  <p><span>{ redux_lang === 'pt' ? 'Telefone' : 'Telephone' }:</span> { itemExpanded?.telefone }</p>
                ) : null
              }
            </SS.Infos>
          </SS.Content>

        </SS.Conteudo>
      </SU.Modal>

    </SS.Container>
  )
}

const mapStateToProps = state => ({
  redux_lang: state.lang
})

export default connect(mapStateToProps)(MenuItens)