import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color:#FFFFFF;
`

export const LeftSide = styled.div`
  padding-left: 10px;
  img{
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }
  button{
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
  }
`
export const Center = styled.div``

export const RightSide = styled.div`
  width: 50px;
  height: 100%;
  background-color: #273294;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  background-color: #FFF;
  text-align: right;
  bottom: 0;
 &.expandid{
    transition: bottom ease-in-out 0.5s;
    bottom: 0;
  }
  &.escondid{
    transition: bottom ease-in-out 0.5s;
    bottom: -150vh;
  }
  >button{
    width: 50px;
    height: 50px;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }
`

export const Selecao = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  >h1{
    font-size: 16px;
    color: #273294;
    font-weight: 500;
  }
  >div{
    margin: 20px;
    >button{
      padding: 0 10px;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
`