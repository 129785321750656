import { io } from 'socket.io-client'

import {
  API
} from './links.json'

const socket = io(API.PRODUCTION, {
  autoConnect: false,
  auth: {
    room: 'app'
  }
})

// socket.onAny((eventName, ...args) => console.log({ eventName, args }))

export default socket
