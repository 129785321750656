import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 80px;
  position: relative;
`

export const ListItem = styled.div`
  padding: 10px 20px;
`
