import React from 'react'
import { Link } from 'react-router-dom'
import * as S from './styles'

function BoasVindas({ user, pt }) {
	return (
		<S.Container>
			<span>{pt ? 'Seja bem vindo(a)' : 'Welcome'},</span>
			<Link to={`/${user ? 'perfil' : 'logar'}`}>
				<h1>
					{user
						? `${user.Nome} ${user.Sobrenome} `
						: pt
						? 'Clique aqui para começar'
						: 'Click here to start'}
				</h1>
			</Link>
			{!user?.adm ? (
				<h2>
					{pt ? 'Apartamento' : 'Apartment'}{' '}
					<span>{user ? user.CodUH : pt ? 'não informado' : 'uninformed'}</span>
				</h2>
			) : null}
		</S.Container>
	)
}

export default BoasVindas
