import styled from 'styled-components';

export const CarrinhoButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
`

export const Conteudo = styled.div`
  width: 100%;
  padding: 14px 20px;
  height: inherit;
  background-color: #FFF;
  position: relative;
  top: 50px;
  overflow: auto;
  border-radius: 5px 5px 0;
  bottom: 0;
  
  span{
    font-size: 12px;
    color: #959595;
  }
`

export const CarrinhoConteudo = styled.div`
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: #FFF;
  bottom: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  transition: bottom ease-in-out 0.5s;
  &.expandido{
    transition: bottom ease-in-out 0.5s;
    bottom: 0;
  }
  &.escondido{
    transition: bottom ease-in-out 0.5s;
    bottom: -100vh;
  }
  
`

export const Cabecalho = styled.div`
  position: relative;
  display: flex;
  >h1{
    margin: 0 auto;
  }
  button{
    position: absolute;
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;
  }
`

export const Entrega = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column; 
  >span{
    font-size: 12px;
    color: #959595;
    line-height: 14px;
    margin-bottom: 9px;
  }
  >select{
    height: 40px;
    background-color:#E4E4E4;
    border-radius: 5px;
    outline: none;
    border: none;
    color: #959595;
    font-size: 12px;
    line-height: 20px;
    padding: 10px;
    margin-bottom: 20px;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  >span{
    margin-top: 20px;
    font-size: 12px;
    color: #444444;
    line-height: 14px;
    margin-bottom: 9px;
  }
  >button{
    align-self: center;
    max-width: 500px;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
  }
  button.azul{
    background-color: #3C86E3;
    color: #FFFFFF;
  }
  button:disabled {
    pointer-events: none;
    cursor: inherit;
  }
`
export const Itens = styled.div`
    >h1{
      text-align: end;
      font-size: 14px;
      font-weight: 500;
      padding: 20px 0;
      color: #444444;
      span{
        color: #444444;
        font-size: 16px;
        font-weight: bold;
      }
   }
`
