import React from 'react'
import * as S from './style'

import * as UTILS from '../../../utils'

//imagem
import { useMemo } from 'react'
import seta from '../../../assets/images/MeusPedidos/CardPedido/seta.svg'

function CardPedido({ data, pt }) {
	const isMan = useMemo(() => {
		return data._schema == 'manutencao' || data._schema === 'tecnologia'
	}, [])

	const itens = data.itens
	const servicos = data.servicos

	const service_fee = data.taxa_de_servico || 0

	const total =
		itens &&
		itens
			.map(({ valor, quantidade }) => {
				return valor * quantidade
			})
			.filter((v) => !!v)
			.reduce((acc, total) => +acc + +total, 0)

	const service_fee_price = (total * service_fee) / 100

	return (
		<S.Container>
			<div>
				<h1>
					{pt ? 'Pedido' : 'Request'} #{data.pedido_id}
				</h1>
				<h2>{data.data[pt ? 'pt' : 'us']}</h2>
				<div className='detalhes'>
					<div className='itens'>
						{isMan ? (
							<span>
								{pt
									? data._schema == 'manutencao'
										? 'Manutenção'
										: 'Manutenção de TI'
									: data._schema == 'manutencao'
									? 'Maintenance'
									: 'IT Maintenance'}
							</span>
						) : (
							servicos.map((servico, index) => {
								const { quantidade } = itens[index]

								return (
									<span key={servico._id}>
										{quantidade} {servico.titulo[pt ? 'pt' : 'us']}
									</span>
								)
							})
						)}
					</div>
					{+total ? (
						<h1>
							Total:{' '}
							<strong>{UTILS.getPrice(total + service_fee_price)} </strong>
						</h1>
					) : null}
				</div>
				<div className='status'>
					<h2>
						Status:{' '}
						<span
							className={UTILS.getStatus({ status: data.status }).toLowerCase()}
						>
							{UTILS.getStatus({
								status: data.status,
								lang: pt ? 'pt' : 'us',
								manutencao: isMan,
							})}
							{!isMan && data.status == 1 && data.entregue
								? ` - ${data.entregue.hora}`
								: ''}
						</span>
					</h2>
					{data.status == 1 && isMan && data.entregue ? (
						<h2>
							Resolvido:{' '}
							{`${data.entregue.semana}, ${data.entregue.dia_e_mes} ${data.entregue.hora}`}
						</h2>
					) : null}
				</div>
			</div>
			<img src={seta} alt='' />
		</S.Container>
	)
}

export default CardPedido
