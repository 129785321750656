import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 179px;
  background: rgb(39,50,148);
  background: linear-gradient(34deg, rgba(39,50,148,1) 0%, rgba(0,105,60,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  span{
    font-size: 14px;
  }
  h1{
    font-size: 26px;
    font-weight: bold;
    line-height: 32px;
    padding-bottom: 30px;
  }
  h2{
    font-size: 16px;
    font-weight: normal;
  }
  h2 span{
    font-size: 16px;
    font-weight: bold;
  }
`