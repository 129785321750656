import * as LOCALSTORAGE from "../../../localStorage"

// ESSA PROPRIEDADE É PARA IDENTIFICAR O QUE EU QUERO MUDAR NO ESTADO
const type = 'LANGUAGE'

export default {
  type,
  // call() É A FUNCAO PARA RETORNAR UM OBJETO QUE QUE VAI SER O action DO fall()
  // CHAMADO  PELOS COMPONENTES
  call(data) {
    return {
      type,
      data
    }
  },
  // fall() RECEBE O ESTADO ATUAL E O OBJETO RETORNADO PELA FUNCAO ACIMA call()
  // CHAMADO PELO REDUCER E DEVE RETORNAR O ESTADO ATUALIZADO
  fall(state, action) {
    const new_state = { ...state }

    new_state.lang = action.data

    LOCALSTORAGE.lang.set(action.data)

    return new_state
  }
}
