import styled from "styled-components";

export const Topo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  
  > h2 {
    font-size: 12px;
    font-weight: bold;
    color: #222;
  }
`;

