import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as S from './style'

import USER from '../../../services/redux/reducer/actions/user'

import API, { endpointers } from '../../../services/api'
import * as LOCALSTORAGE from '../../../services/localStorage'
import SOCKET from '../../../services/socket'
import { getString, submit_state } from '../../../utils'

function Formulario({ redux_setUser, redux_lang, redirect }) {
	const [data, setData] = useState({})
	const [submit, setSubmit] = useState({ ...submit_state })

	const { addToast } = useToasts()

	const { push } = useHistory()

	const _handleSubmit = useCallback(
		async (e) => {
			e.preventDefault()

			// SE JÁ ESTIVER FAZENDO A REQUISICAO, RETORNE OU MOSTRE UM AVISO NA TELA
			if (submit.req) return

			setSubmit((oldState) => ({
				...oldState,
				req: true,
			}))

			let status = true

			try {
				const { data: dataFromApi } = await API.post(
					endpointers.post.login,
					data
				)

				if (!dataFromApi.ok) {
					addToast(dataFromApi.message, {
						autoDismiss: true,
						appearance: 'info',
					})
					throw new Error(getString(dataFromApi.message))
				}

				// SOCKET.auth = data

				SOCKET.connect()

				LOCALSTORAGE.token.set(dataFromApi.token)

				redux_setUser({
					...dataFromApi.data,
					token: dataFromApi.token,
				})
			} catch (e) {
				status = false
			} finally {
				setSubmit({
					req: false,
					done: true,
					status,
				})

				if (status) {
					push(redirect ? `/${redirect}` : '/')
				}
			}
		},
		[data, submit, redux_lang]
	)

	const _changeData = useCallback(({ target: { name, value } }) => {
		setData((oldState) => ({ ...oldState, [name]: value }))
	}, [])

	return (
		<S.Container>
			<h1>{redux_lang === 'pt' ? 'Login de acesso' : 'Sign-in'}</h1>
			<form onSubmit={_handleSubmit} autoComplete='off'>
				<div className='group'>
					<label htmlFor='sobrenome'>
						{redux_lang === 'pt' ? 'Sobrenome' : 'Surname'}
						{': '}
					</label>
					<input
						type='text'
						name='sobrenome'
						id='sobrenome'
						required
						value={data.sobrenome || ''}
						onChange={_changeData}
					/>
				</div>
				<div className='group'>
					<label htmlFor='apartamento'>
						{redux_lang === 'pt' ? 'Número do apartamento' : 'Apartment number'}
						{': '}
					</label>
					<input
						type='text'
						name='apartamento'
						id='apartamento'
						required
						value={data.apartamento || ''}
						onChange={_changeData}
					/>
				</div>
				<button disabled={submit.req}>
					{submit.req
						? `${redux_lang === 'pt' ? 'Acessando' : 'signing in'} ...`
						: redux_lang === 'pt'
						? 'Acessar'
						: 'Sign-in'}
				</button>
			</form>
		</S.Container>
	)
}

//
const mapDispatchToProps = (dispatch) => ({
	redux_setUser(data) {
		// USER.call RETORNARA UM OBJETO
		// {
		//   type: 'USER',
		//   data: [PARAMETRO]
		// }
		// E MANDA COMO SEGUNDO PARAMETRO(action) PARA O REDUCER (state, action) => { ... }
		dispatch(USER.call(data))
	},
})

const mapStateToProps = (state) => ({
	redux_lang: state.lang,
})

// PRIMEIRA PARAMETRO É UMA FUNCAO QUE RECEBE O ESTADO ATUAL E RETORNA UM OBJETO COMO PROPS DO COMPONENTE
// SEGUNDO PARAMETRO É UMA FUNCAO QUE RECEBE UMA FUNCAO QUE CHAMA O REDUCER PARA MUDAR O ESTADO E RETORNA UM OBJETO COMO PROPS DO COMPONENTE

export default connect(mapStateToProps, mapDispatchToProps)(Formulario)
